import MainScreen from "./components/MainScreen/MainScreen.component";
import firepadRef, { db, userName, tableId, tableStatus, tableName,lockStatus, userCode } from "./server/firebase";
import "./App.css";
import { useEffect, useState, useRef } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "./store/actioncreator";
import { connect } from "react-redux";
import TableService from "./services/TableService";

function NubvaRoom(props) {
  const [leaveRoom, setLeaveRoom] = useState(); 

  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };

  // useEffect(() => {
    
  // });

  useEffect(async () => {
    
    const stream = await getUserStream();
    stream.getVideoTracks()[0].enabled = false;
    props.setMainStream(stream);

   
    db.database().ref().child(tableId).child("participants").on('value', snapshot =>{
     // for (const [key, row] of Object.entries(snapshot.val())) { 
         let userCount = 0;
         let usersArray = [];
        for (const [index, value] of Object.entries(snapshot.val())) {  
           if(value.userName === null || value.userName === 'undefined' || value.userName === undefined){
            db.database().ref().child(tableId).child("participants").child(index).remove(); 
          }

          if(value.userCode === userCode){ 
           userCount = userCount+1;
           usersArray.push(index);
          } 
          
            if(userCount>1){
              let keyIndex = usersArray[0];
              db.database().ref().child(tableId).child("participants").child(keyIndex).remove(); 
            }
          
          
        }
      //}

    }) 

    connectedRef.on("value", (snap) => {
       if (snap.val()) {
       
        // get table id and user name and identify new or existing table.
        if(tableId){   
          if(tableStatus === "create"){   

          TableService.createTable("81dc9bdb52d04dc20036dbd8313ed055", tableId,userName,lockStatus,tableName);   
          }
          
          if(tableStatus === "join"){
           TableService.updateTable("81dc9bdb52d04dc20036dbd8313ed055", tableId,userName);   
          }
                
        } 

        
        const defaultPreference = {
          audio: true,
          video: false,
          screen: false, 
        };


        const userStatusRef = participantRef.push({
          userName, 
          userCode,
          preferences: defaultPreference,
        });

         props.setUser({
          [userStatusRef.key]: { name: userName, userCode:userCode, ...defaultPreference },
        }); 
         
          if(tableName !== undefined && lockStatus !== undefined){
            const tableNameData = {tableName: tableName, lockStatus:lockStatus};
              db.database().ref(tableId).push(
                tableNameData,
                err =>{
                  if(err){
                    console.log(err);
                  } 
                }
              )
            } 

        userStatusRef.onDisconnect().remove();
      }  
     });
     
  }, []);

  const connectedRef = db.database().ref(".info/connected");
  const participantRef = firepadRef.child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name, 
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [isStreamSet, isUserSet]);
  
//   const onLeaveClick = () =>{
//     window.close();
// }
  
  return (
    <div className="Room">
      <MainScreen />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NubvaRoom);
