import "./App.css";
import "./components/roomTables.css";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from  "./Home"
import Room from "./Room";
import { Suspense, useEffect, useState } from "react";
import Header from "./Header";
import firebase from "firebase";
import firepadRef, { db, userName } from "./server/firebase";
// import RoomTables from "./components/RoomTables";
import JoinRoom from "./components/JoinRoom";
import AdminLogin from "./AdminLogin"; 
import AdminHome from "./AdminHome"; 
import NubvaRoom from "./NubvaRoom";
import JoinNubvaRoom from "./components/JoinNubvaRoom";
function App() { 
  return (
    <Router>
     
      <Suspense fallback={<></>}>
      <Routes>
        <Route exact path="/" element={<Home/>} ></Route>
        <Route exact path='/room' element={<Room/>}></Route>
        <Route exact path='/nuBVARoom' element={<NubvaRoom/>}></Route>
        <Route exact path='/join' element={<JoinRoom/>}></Route>
        <Route exact path='/joinNubvaRoom' element={<JoinNubvaRoom/>}></Route>
        <Route exact path="/admin" element={<AdminLogin/>} ></Route>
        <Route exact path="/adminHome" element={<AdminHome/>} ></Route>
       </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
