import React, { useState } from 'react';
import Header from './Header';
import logo from "./logo.png"

export default function AdminLogin() {
    const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // User Login info
  const credentials = [
    {
      username: "admin@nukg.com",
      password: "test1234"
    }
  ];

  const errors = {
    uname: "Invalid username",
    pass: "Invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = credentials.find((user) => user.username === uname.value);
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
         window.location.href = "/adminHome" + "?uname=" + userData.username + "&pwd=" + userData.password;
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    ); 
    const renderForm = (
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="form-group text-left">
            <label htmlFor="roomName">Username</label> 
            <input type="text" autoComplete="off" className= "form-control"name="uname" required placeholder="Enter username" /> 
              {renderErrorMessage("uname")} 
            <label htmlFor="roomName">Password</label> 
            <input type="password" autoComplete="off" className= "form-control"name="pass" required placeholder="Enter password" />  
              {renderErrorMessage("pass")}
            </div>
            <div className="button-container">
            <button type="submit" className='btn btn-primary createBtn' >Login</button>
              {/* <input type="submit" /> */}
            </div>
          </form>
        </div>
      );

  return (
    <div>
      <div className="container Join-form">
          <div className="row ">
              <div className="col-md-4 offset-md-4 text-center">
                  <div className='form-box'>
                   <img className='main-logo' src={logo} alt="Logo" /> <br/><br/>
                   {renderForm}
             
                  </div> 
                  </div>
                  </div>
              </div>
    </div>
  )
}
