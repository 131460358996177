import React, { Component } from 'react';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import logo from './logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

library.add(faEnvelope);
export class Header extends Component {

  state = {
    isOpen: false
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    return (
      <div>
        
        <div className='logo-padd'>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-8 col-xs-8'>
                    <Link to='/'> <img className='main-logo' src={logo} alt="Logo" /> </Link> 
                  </div>
                  <div className='col-md-4 col-xs-4'>
                      <button className='btn btn-primary headerBtn' onClick={this.openModal}>How to</button>
                      <p className='head-text text-right hidden-xs'>Google Chrome Compatible*</p>
                  </div>
                  <p className='hidden-lg mobile-text'>Google Chrome Compatible*</p>
                  <Modal className='modalPadd' show={this.state.isOpen} onHide={this.closeModal}> 
                    <Modal.Body className='moadlPadd'>
                      <span className='closeBtn' onClick={this.closeModal}>X</span>
                      <h3>How to use ourchaitime?</h3>
                      <ol className='lineHeight'>
                        <li>Click on an empty chair at any of the tables.</li>
                        <li>Enter screen name.</li>
                        <li>Wait for your colleagues to join the table or share the table link with them.</li>
                      </ol>
                      <p className='modal-chromeTitle'>Google Chrome Compatible*</p>
                    </Modal.Body>
                  </Modal>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header;
