import axios from "axios";

const BASE_URl ="http://localhost:8081/";
// const BASE_URl ="http://ec2-54-84-80-24.compute-1.amazonaws.com/";
// const BASE_URl ="http://ec2-44-197-183-234.compute-1.amazonaws.com:81/";

class TableService{

    createTable(trakLoc, tableID, userName, lockStatus, tableName){
        const config = {

            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },

            params: {
                trakLoc : trakLoc,
                tableID : tableID,
                userName : userName,
                lockStatus: lockStatus,
                tableName : tableName
            }
          }
          
          const data = {
             data: 'whatever'
          }
        const  url = BASE_URl+"createTable";

         // alert("url   "+url+"   trakLoc "+trakLoc+"   tableID  "+tableID+"    userName  "+userName);
        axios.post(url, data, config);
    }

    updateTable(trakLoc, tableID, userName){
        const config = {
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
            params: {
                trakLoc : trakLoc,
                tableID : tableID,
                userName : userName, 
            }
          }
          
          const data = {
             data: 'whatever'
          }
        const  url = BASE_URl+"updateTable";

        //  alert(" update url   "+url+"   trakLoc "+trakLoc+"   tableID  "+tableID+"    userName  "+userName);
        axios.post(url, data, config);
    }

    closeTable(trakLoc, tableID){
      const config = {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
              trakLoc : trakLoc,
              tableID : tableID
          }
        }
        
        const data = {
           data: 'whatever'
        }
      const  url = BASE_URl+"closeTable";

     // alert(" close url   "+url+"   trakLoc "+trakLoc+"   tableID  "+tableID);
      axios.post(url, data, config);
  }
  
     
}
export default new TableService();