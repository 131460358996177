import React, { userState, useState, useEffect, Component } from "react";
import { withRouter } from "react-router-dom";
import reactDom from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import firebase from "firebase";
import randomatic from "randomatic";
import { createHashHistory } from "history";
import Header from "./Header";
import firepadRef, { db, userName, tableName } from "./server/firebase";
import "./components/roomTables.css";
import bootstrap from "bootstrap";
import tableImg from "./images/table.png";
// import mainBg from "./main-bg.jpg"
import mainBg2 from "./images/bg4.png"
import * as $ from "jquery";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";
// import RoomTables from "./components/RoomTables"; 
import Table from "./components/Table";
import ReactTooltip from "react-tooltip";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TableService from "./services/TableService";


// export const db = firebase
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createTableStatus: false,
            show: false,
            popoverOpen:false,
            id: "",
            name: "",
            joinId: "",
            // tableName: "",
            joinName: "",
            formErrors: {},
            tableList: [],
            
        };
        //this.connectedRef = db.database().ref();
        this.initialState = this.state;
        // this.handleButtonClicked = this.handleButtonClicked.bind(this) 
    }

    showInputBox = (event) => {
        const { createTableStatus } = this.state;
        this.setState({
            createTableStatus: !createTableStatus,
        });
    };

    handleModal() {
        this.setState({ show: !this.state.show });
    }

    componentDidMount() {
        //const roomsList = [];
        
        db.database()
        .ref()
        .on("value", (snap) => {
        this.setState({ tableList: [] });
        const arrayTables = ["A3RVKRJ2J8Y1", "A3RVKRJ2J8Y2", 'A3RVKRJ2J8Y3', 'A3RVKRJ2J8Y4', 'A3RVKRJ2J8Y5',"A3RVKRJ2J8Y6"];
         const tableNamesList = ["Meetings", "Retrospection", 'Party', ' ', ' '];
        // const roomsList =[];
        let counter = 0;
        arrayTables.map((tableId) =>{
        //alert("counter : "+counter);
        db.database().ref().child(tableId).on('value', snapshot =>{
        if(snapshot.val() === undefined || snapshot.val() === null){
        const emptySeats = [];
        const chairs = [];
        let emptyStatus = false; 
        const emptyJoinees = [];
        for(let i=1;i<=6;i++){
            // emptySeats.push("Join");
            chairs.push({ id: i , status:false, userName: ""});
        }
         emptyStatus = true;
        //console.log("emptySeats "+emptySeats);
        if(counter == 5){
           // alert("all tables empty");
            var randNames = ["Ravi ", "Siva", "Prasad  ", "Manoj", "Prakash", "Deepak", "Srinivas", "Vasu", "Joseph", "Sai", "Sri", "Ram"];
            var randomName1 = randNames[Math.floor(Math.random()*randNames.length)];
             var randomName2 = randNames[Math.floor(Math.random()*randNames.length)];
            var randomName3 = randNames[Math.floor(Math.random()*randNames.length)];
             var randTableNames = ["Java ", "Python ", "SQL", "UI","React Js"];
            var randTableName = randTableNames[Math.floor(Math.random()*randTableNames.length)];  
             const emptySeats = [];
            const chairs = [];
            const tableNames =[];
            chairs.push({ id: 1 , status:false, userName: randomName1, lock: 'true'});
            chairs.push({ id: 2 , status:false, userName: randomName2, lock: 'true'});
            chairs.push({ id: 3 , status:false, userName: randomName3, lock: 'true'});
            chairs.push({ id: 4 , status:false, userName: '', lock: 'true'});
            chairs.push({ id: 5 , status:false, userName: '', lock: 'true'});
            chairs.push({ id: 6 , status:false, userName: '', lock: 'true'});
            tableNames.push(randTableName);
            
            
            this.setState((prevState) => ({
                tableList: [...prevState.tableList, {id: "A3RVKRJ2J8Y1", users: chairs, empty:emptySeats, emptyStatus : false, tableName: randTableName, lockList: 'private'}],
                }));
        }else{
            this.setState((prevState) => ({
                tableList: [...prevState.tableList, { id: tableId, users: chairs, empty:emptySeats, emptyStatus : emptyStatus, tableName : tableNamesList[counter] }],
                }));
        }
        
        counter = counter+1;
        TableService.closeTable("81dc9bdb52d04dc20036dbd8313ed055", tableId);
        //
        }else{     
        const joinees = [];
        const emptySeats = [];
        const chairs = [];
        const tableNames =[];
        const lockList=[];
        // alert("snapshot.val()  "+snapshot.key);
        for (const [key, row] of Object.entries(snapshot.val())) {

            if(row.tableName != null && row.tableName != undefined && row.tableName != "" && row.tableName != "undefined" && row.tableName != "empty"){
                tableNames.push(row.tableName);
            } 
            if(row.lockStatus != null && row.lockStatus != undefined && row.lockStatus != "" && row.lockStatus != "undefined" && row.tableName != "empty"){
                lockList.push(row.lockStatus);
            } 
            
        for (const [index, value] of Object.entries(row)) {

            if (value.userName != null && value.userName != undefined && value.userName != "") {
            joinees.push(value.userName);
            }
        }
        } 

        // tableNames.push(snapshot.val().tableName); 
        if(joinees.length < 1){
            db.database().ref().child(tableId).remove(); 
            TableService.closeTable("81dc9bdb52d04dc20036dbd8313ed055", tableId);
            // db.database().ref().child(tableId).child(tableName).remove(); 
        }
        // alert("lenth is"+child.key.length);
        if (tableId.length == 12 && joinees.length > 0) {
        //roomsList.push({id:child.key, users:joinees});
        let empty = 0;
        let emptyStatus = false;
        for(let i=0;i<joinees.length;i++){
            // chairs.push({ id: i, status:true, userName: joinees[i]});
        if(lockList[0] === 'private'){
            chairs.push({ id: i, status:true, userName: joinees[i], lock: 'true'});
            
        }else{
            chairs.push({ id: i, status:true, userName: joinees[i],  lock: 'undefined'});
        }
        }
        if(joinees.length<6){
        empty = 6 - joinees.length;
        emptyStatus = true;
        }
        for(let i=1;i<=empty;i++){
        emptySeats.push("Join");
        // chairs.push({ id: joinees.length+i , status:false, userName: ''});
        if(lockList[0] === 'private'){
            emptyStatus = false;

            chairs.push({ id: joinees.length+i , status:false, userName: '', lock: 'true'});
        }else{
            chairs.push({ id: joinees.length+i , status:false, userName: '', lock: 'undefined'});
        }
       

        }
      
        //console.log("emptySeats "+emptySeats);
        this.setState((prevState) => ({
        tableList: [...prevState.tableList, { id: tableId, users: chairs, empty:emptySeats, emptyStatus : emptyStatus, tableName: tableNames, lockList: lockList[0]}],
        }));
        }
        
        }
       
        });
        });

        });
        }
   
    

    //Join Meeting End

    render() {
        const myStyle={
            background: '#f7eadb',
            backgroundImage: `url(${tableImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        const mainBgStyle={
            // background: '#f7eadb',
            backgroundImage: `url(${mainBg2})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        const { idErr, nameErr, joinIdErr, joinNameErr } = this.state.formErrors;
        return (
            <div>
                <Header />
                <div>
                    <section style={mainBgStyle} className="iq-features">
                        <div className="container-fluid">
                            <div className="row align-items-center pb-30">
                           
                                {this.state.tableList.map((echItm, index) => (
                                    <div className="col-lg-4 col-md-12 mb-40">
                                       <div className="tableBg">
                                        <Table echItm={echItm} key={echItm.id}></Table>
                                       </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
               
            </div>
        );
    }
}
export default Home;
