import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "./MeetingFooter.css";
import userStatusRef from '../../Room';
import Room from "../../Room";
import { connect } from "react-redux";


const MeetingFooter = (props) => {
  const [meetingLinkCss, setMeetingLinkCss] = useState(false);
  const [url, setUrlState] = useState('');
  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });

  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onLeaveClick = () =>{
    // alert("leave room");
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: false,
        video: false,
      };
    });

    window.location.href = "/"; 

  }
  // const leaveMeeting = () => {
  //   userStatusRef.onDisconnect().remove();
  // };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };

  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };
  /*
  useEffect(()=>{
    const urlparams = new URLSearchParams(window.location.search); 
    const meetingUrl = "https://www.ourchaitime.com/join?id=A1D"+urlparams.get("id")+"P6G";
    const idVal = urlparams.get("id");
    const nameVal = urlparams.get("name");

    if (idVal === '' || idVal === null ) {
      alert('Meeting Id should not be empty');
      window.location.href = "/";  
    } 
    if (nameVal === '' || nameVal === null ) {
      alert('Screen Name should not be empty');
        window.location.href = "/";  
    } 
    setUrlState(meetingUrl);
  },[]);*/

  useEffect(()=>{
    const urlparams = new URLSearchParams(window.location.search);
    // const meetingUrl = ""+urlparams.get("id");
    const arrayTables = ["A3RVKRJ2J8Y1", "A3RVKRJ2J8Y2", 'A3RVKRJ2J8Y3', 'A3RVKRJ2J8Y4', 'A3RVKRJ2J8Y5',"A3RVKRJ2J8Y6"];
    const mainRoomId =  urlparams.get("id");
    // alert("mainRoomId  "+mainRoomId);
    if(arrayTables.includes(mainRoomId)){
      const meetingUrl = "https://ourchaitime.com/join?id=A1D"+mainRoomId+"P6G";
      // alert("meetingUrl 1"+meetingUrl);
      setUrlState(meetingUrl);
    }else{
      const meetingUrl = "https://ourchaitime.com/joinNubvaRoom?id=A1D"+mainRoomId+"P6G";
       // alert("meetingUrl 2"+meetingUrl);
      setUrlState(meetingUrl);
    }
    
    const idVal = urlparams.get("id");
    const nameVal = urlparams.get("name");

    if (idVal === '' || idVal === null ) {
      alert('Meeting Id should not be empty');
      window.location.href = "/";  
    } 
    if (nameVal === '' || nameVal === null ) {
      alert('Screen Name should not be empty');
        window.location.href = "/";  
    } 
    // setUrlState(meetingUrl);
  },[]);


  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);


  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);
  
  return (
    
    <div className="meeting-footer">
      <div className="meetingLink">
       <h6 data-tip="Click to copy" data-for='toolTip1' data-place='bottom' className= {meetingLinkCss ? 'meetingLinkCss' : ''} onClick={() => {setMeetingLinkCss(false);navigator.clipboard.writeText(url);setMeetingLinkCss(true); }}><FontAwesomeIcon icon={faCopy} />&nbsp;Meeting Link</h6>
        <span className= {meetingLinkCss ? 'fadeText' : 'fadeTextHide'}>Copied</span>
      </div>
      <div
        className={"meeting-icons " + (!streamState.mic ? "active" : "")}
        data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
        onClick={micClick}
      >
        <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        />
      </div>
      <div
        className={"meeting-icons " + (!streamState.video ? "active" : "")}
        data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
      >
        <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} />
      </div>

      <div
        className="meeting-icons"
        data-tip="Share Screen"
        onClick={onScreenClick}
        disabled={streamState.screen}
      >
        <FontAwesomeIcon icon={faDesktop} />
      </div>

      <ReactTooltip id="toolTip1" />
       <div>
        <button className="btn btn-danger leaveBtn" onClick={onLeaveClick}>Leave</button>
      </div>
      <span className="room-head">Google Chrome Compatible*</span>
    </div>
  );
};

export default MeetingFooter;
