import React,{useRef, useEffect, useState} from 'react'; 
import { Popover, PopoverBody } from "reactstrap";
import createTable from "../images/createTable.png"; 
import { tableName, tableStatus, userCode } from '../server/firebase'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLock,
} from "@fortawesome/free-solid-svg-icons";

export default function Table({echItm}) {
  const [show, setShow] = useState(false);
 // const [seatId, setSeatId] = useState(0);
  // const [empSeatId, setEmpSeatId] = useState(0);
  const [meetId, setMeetId] = useState();
  const [screenName, setScreenName ] = useState();
  var [tableVal, setTableVal ] = useState();
  const [validation, setValidation] = useState(false);
  // const [validationLen, setValidationLen] = useState(false);
  const [joinTableBox, setjoinTableBox] = useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  var [lockStatus, setLockStatus] = useState();
  const handleClose = () => setShow(false);

  const nameRef = useRef();

  const createMeeting=() =>{
    // alert("create meeting");

    const arrayTables = ["A3RVKRJ2J8Y1", "A3RVKRJ2J8Y2", 'A3RVKRJ2J8Y3', 'A3RVKRJ2J8Y4', 'A3RVKRJ2J8Y5',"A3RVKRJ2J8Y6"];
    const randomHex = length => ( '0'.repeat(length) + Math.floor((Math.random() * 16 ** length)).toString(16)).slice(-length);
    const userCode = randomHex(16);
    // alert("uniqueUserCode  "+uniqueUserCode);
      if(arrayTables.includes(meetId)){
        if(meetId.length !== 12){ 
          alert("Invalid table id");
      }else{
        if(screenName === null || screenName === undefined || screenName === "" || screenName.length < 1){
          nameRef.current.focus();
          // nameRef.current.style.border = "1px solid red";
          setValidation(true);
        }else{
          setValidation(false); 
           const status = "create";
           if(tableVal === null || tableVal === undefined || tableVal === "" ){
             tableVal = "undefined";
           }
          //  alert("lockStatus  "+lockStatus);
           if(lockStatus === null || lockStatus === undefined || lockStatus === "" || lockStatus === false ){
            lockStatus = 'public';
          }else{
            lockStatus = 'private';
          }

           window.location.href = "/room" + "?id=" + meetId + "&name=" + screenName+ "&tableName="+tableVal+"&lockStatus="+lockStatus+"&status="+status+"&userCode="+userCode;
        }
      }
      }else{
        alert("Table doesn't exist");
      }
  }

    const joinMeeting = () =>{ 
      // alert("tableNam  "+tableVal);
      // const status = "create"; 
      const arrayTables = ["A3RVKRJ2J8Y1", "A3RVKRJ2J8Y2", 'A3RVKRJ2J8Y3', 'A3RVKRJ2J8Y4', 'A3RVKRJ2J8Y5',"A3RVKRJ2J8Y6"];
      const randomHex = length => ( '0'.repeat(length) + Math.floor((Math.random() * 16 ** length)).toString(16)).slice(-length);
      const userCode = randomHex(16);
      if(arrayTables.includes(meetId)){
        if(meetId.length !== 12){ 
          alert("Invalid table id");
      }else{
        if(screenName === null || screenName === undefined || screenName === "" || screenName.length < 1){
          nameRef.current.focus();
          // nameRef.current.style.border = "1px solid red";
          setValidation(true);
        }else{
          setValidation(false); 
           const status = "join"; 
           window.location.href = "/room" + "?id=" + meetId + "&name=" + screenName + "&tableName="+'undefined'+"&lockStatus="+'undefined'+"&status="+status+"&userCode="+userCode;
       }
      }
      }else{
        alert("Table doesn't exist");
      }
      // alert("meetId  "+meetId);
       
    }
    function onChangeValue(event) { 
      setLockStatus(event.target.value); 
    }

    const handleShow = (id) =>{
      setMeetId(id)
      setShow(true)
    }

    const handleTableShow = (id) =>{
      setMeetId(id)
      setShow(true)
      setjoinTableBox(true)
    }
    

   function handleTableId(e){
    setMeetId(e.target.value);
   }

    function handleScreenName(e) {
      setScreenName(e.target.value); 
      // alert("screenName.length"+screenName.length);
      if(screenName === null || screenName === undefined || screenName === " " || screenName.length < 1){  
        setValidation(true);

      }else {
        setValidation(false);
        // setValidationLen(false);
      }

    }

    function handleTableName(e) {
      setTableVal(e.target.value);  
    }
    
    const graph = useRef(null);
    
    useEffect(() => {
      const ciclegraph = graph.current;
      const circleElements = ciclegraph.childNodes;
      setMeetId(echItm.id); 
      
       let angle = 360 - 90;
      let dangle = 360 / circleElements.length;
      
      for (let i = 0; i < circleElements.length; i++) {
        let circle = circleElements[i];
        angle += dangle;
        circle.style.transform = `rotate(${angle}deg) translate(${ciclegraph.clientWidth / 2}px) rotate(-${angle}deg)`;
          
      }
    }, []);



  
    return (
      
     
      <div>
        <span ><span className={echItm.lockList === "private" ? 'removeTable': 'removeTable2'} >{echItm.tableName !== 'undefined' ? echItm.tableName : ''}</span>&nbsp;<span className={echItm.lockList === "private" ?'lock-icon':''} >{echItm.lockList === "private" ? <FontAwesomeIcon  icon={faLock} /> : ''}</span> </span>  
        {/* <span className='removeTable' >{echItm.tableName !== 'undefined' ? echItm.tableName[0] : ''}</span> */}
         <div className="ciclegraph" ref={graph}>
         {echItm.users.map((user, seatId) =>  
               (user.lock === 'true') ? 
              <div className={ user.status ? 'circle fillChair': 'circle emptyChair'}>
              <img className={ user.status ? 'first-img1 chairAngle'+seatId: 'first-img1 chairAngle'+seatId}    src={ user.status? require('./../images/fillChair'+ seatId +'.png').default: require('./../images/empChair'+ seatId +'.png').default} alt="chair" />
               <div id={user.userName !== '' ? "arrow" :''}>
             <span className="badge titleNameJoin2">{user.userName}</span></div>
            </div> :

             <div className={ user.status ? 'circle fillChair': 'circle emptyChair'} id={ !user.status?'popover'+echItm.id:''}>
             <img className={ user.status ? 'first-img1 chairAngle'+seatId: 'first-img1 empChairImg chairAngle'+seatId}    src={ user.status? require('./../images/fillChair'+ seatId +'.png').default: require('./../images/empChair'+ seatId +'.png').default} alt="chair" />
             <div id={user.userName !== '' ? "arrow" :''}>
             <span className="badge titleNameJoin2">{user.userName}</span></div>
            </div> 
        )} 
        
         {echItm.emptyStatus && <Popover
            placement="bottom" isOpen={popoverOpen}
            target={'popover'+echItm.id} toggle=
                {() => { setPopoverOpen(!popoverOpen) }}> 
            <PopoverBody>
                <input type="hidden" value={echItm.id} onChange={(e) => handleTableId(e)}></input> 
                <input type="text" ref={nameRef} autoComplete="off" className={echItm.tableName !== '' ? 'form-control joinTableInput2':'form-control joinTableInput'}  required onChange={(e) => handleScreenName(e)} id="screenName" placeholder="Enter Screen Name * " />
                {validation && <p className='errorMsg'>Screen name should not be empty </p>}
                {echItm.tableName !== '' ? '': <input type="text" value={tableVal} autoComplete="off" className= "form-control joinTableInput" required onChange={(e) => handleTableName(e)}  id="tableName" placeholder="Enter Table Name" /> }
               
                {echItm.tableName !== '' ? '':<div className='clearfix'></div>}
                {echItm.tableName !== '' ? '':<span className='radioInput' >
                  <label>Table Type</label><br></br>
                  <input type="radio" value="true" name="lockStatus" onChange={onChangeValue} /> Private
                  <input type="radio" value="false" name="lockStatus"  onChange={onChangeValue} defaultChecked /> Public 
                </span>}
                {echItm.tableName !== '' ?  <img className={echItm.tableName !== '' ? 'joinTableImg':'joinLockImg'} src={createTable} alt="Logo" onClick={()=>joinMeeting()} /> : <img className={echItm.tableName !== '' ? 'joinTableImg':'joinLockImg'} src={createTable} alt="Logo" onClick={()=>createMeeting()} />}
                
                {/* {validationLen && <p className='errorMsg'>Screen name should be more than 2 letters </p>} */}
                
              </PopoverBody>
          </Popover>}
          
        </div>
      </div>
    );
}
