import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDVRWRUm11WC1unkCS9IZHAgAdbsMq6Xxk", // Add API Key
  databaseURL:"https://our-chai-time-default-rtdb.firebaseio.com/" // Add databaseURL

  /*apiKey: "AIzaSyCdZ8mhEYJGgn25LwInwnDGgR7CvU8yCgA", // Add API Key
  databaseURL:"https://video-conference-a6d8d-default-rtdb.asia-southeast1.firebasedatabase.app/" // Add databaseURL*/
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref(); 
// export const userName = prompt("What's your name?");
const urlparams = new URLSearchParams(window.location.search);
const roomId = urlparams.get("id");
export const userName =  urlparams.get("name");
export const tableId =  roomId;
export const tableStatus = urlparams.get("status");
export const userCode = urlparams.get("userCode");
 var tableNameVal =  urlparams.get("tableName");
var lockStatusVal =  urlparams.get("lockStatus");

if(tableNameVal === undefined || tableNameVal === null){  
  tableNameVal = "empty";
} 
export const  tableName = tableNameVal;

if(lockStatusVal === undefined || lockStatusVal === null){  
  lockStatusVal = "public";
} 
export const  lockStatus = lockStatusVal;


// alert("tableName  "+tableName);
// alert("lockStatus  "+lockStatus);

if (roomId) {
  firepadRef = firepadRef.child(roomId);
} 

export default firepadRef;
