import React, { Component } from 'react';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import logo from './logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { Modal, Button } from "react-bootstrap";
import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";

library.add(faEnvelope);
export class AdminHeader extends Component {
//     constructor(props) {
//         super(props);       
//         this.logout = this.logout.bind(this) 
//     }
//   state = {
//     isOpen: false
//   };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  logout() {
        window.location.href="/admin";
    }

  render() {
    return (
      <div>
        
        <div className='logo-padd'>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-8 col-xs-8'>
                      <img className='main-logo' src={logo} alt="Logo" /> 
                  </div>
                  <div className='col-md-4 col-xs-4'> 
                        <p className="logout" onClick={this.logout.bind(this)}>Logout</p>
                  </div>
                 
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminHeader;
