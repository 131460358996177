import React, { userState, useState, useEffect, Component } from "react";
import { withRouter } from "react-router-dom";
import reactDom from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import firebase from "firebase";
import randomatic from "randomatic"; 
import Header from "./Header";
import firepadRef, { db, userName } from "./server/firebase";
import "./components/roomTables.css";
import bootstrap from "bootstrap";
import tableImg from "./images/table.png"; 
import mainBg2 from "./images/bg4.png"
import AdminTables from "./components/AdminTables";
import AdminHeader from "./AdminHeader";
import { tableName } from "./server/firebase";

export class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show: false, 
            mainText:true,
            id: "",
            name: "",
            joinId: "",
            joinName: "",
            formErrors: {},
            tableList: [],
            
        };
        this.initialState = this.state; 
    }
       

    componentDidMount() {
        //const roomsList = [];
        const urlparams = new URLSearchParams(window.location.search);
        const uname = urlparams.get("uname"); 
        const pwd = urlparams.get("pwd"); 

        if(uname === "admin@nukg.com" && pwd ==="test1234"){
        db.database()
            .ref()
            .on("value", (snap) => {
                this.setState({ tableList: [] });
                this.setState({mainText:true});
                // const roomsList =[];
                snap.forEach((child) => { 
                this.setState({mainText:false});
                    const joinees = [];
                    const emptySeats = [];
                    const chairs = [];
                    const userKeys = [];
                    const tableNames =[];
                    // alert("idd "+child.key);
                    for (const [key, row] of Object.entries(child.val())) {  
                        if(row.tableName != null && row.tableName != undefined && row.tableName != '' && row.tableName != "undefined"){
                            tableNames.push(row.tableName);
                        } 
                        for (const [index, value] of Object.entries(row)) { 
                            //roomsList.push(rList[id]);
                            // alert("Parcipent name : "+value.userName);
                            // alert("user Name:  "+value.userName);
                            if (value.userName != null && value.userName != undefined && value.userName != "") {
                                joinees.push(value.userName);
                                userKeys.push(index);
                            }
                        }
                    }
                    // alert("lenth is"+child.key.length);
                    if (child.key.length == 12 && joinees.length > 0) {
                        //roomsList.push({id:child.key, users:joinees});
                        let empty = 0;
                         let emptyStatus = false;
                         for(let i=0;i<joinees.length;i++){
                            chairs.push({ id: i, status:true, userName: joinees[i], userKey: userKeys[i]});
                         }
                        if(joinees.length<6){
                            empty = 6 - joinees.length;
                            emptyStatus = true;
                        }
                        for(let i=1;i<=empty;i++){
                            emptySeats.push("Join");
                            chairs.push({ id: joinees.length+i , status:false, userName: ''});
                        }
                        // alert("table   "+tableNames[0]);
                        // console.log("emptySeats  "+emptySeats);
                        this.setState((prevState) => ({
                            tableList: [...prevState.tableList, { id: child.key, userKey:userKeys, users: chairs, empty:emptySeats, tableName: tableNames[0],emptyStatus : emptyStatus }],
                        }));
                    }
                }); 
            });
        }else{  
            alert("Invalid username or password");
            window.location.href ="/admin";
        }
    }

    //Join Meeting End

    render() {
        const myStyle={
            background: '#f7eadb',
            backgroundImage: `url(${tableImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        const mainBgStyle={
            // background: '#f7eadb',
            backgroundImage: `url(${mainBg2})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height:'88vh',
        };
 
        return (
            <div>
               <AdminHeader/>
                <div>
                    <section style={mainBgStyle} className="iq-features">
                        <div className="container-fluid">
                            <div className="row align-items-center"> 
                                {this.state.mainText && <h1 className="mt-60 text-center">No Active Tables Found.</h1>}
                                {this.state.tableList.map((echItm, index) => (
                                    <div className="col-lg-4 col-md-12 mb-40">
                                       <div className="tableBg"> 
                                       <AdminTables echItm={echItm} key={echItm.id}></AdminTables>
                                       </div>
                                    </div>
                                ))} 
                            </div>
                        </div>
                    </section>
                </div>
               
            </div>
        );
    }
}

export default AdminHome
