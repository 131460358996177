import React,{userState, useRef, useEffect, Component, useState} from 'react'; 
import { db, userName, tableName, lockStatus, tableStatus } from '../server/firebase';
import Header from '../Header';

export default function JoinRoom( ) {  

   const [meetId, setMeetId] = useState();
   const [screenName, setScreenName ] = useState();
   const [validation, setValidation] = useState(false);
   const [validationLen, setValidationLen] = useState(false);   
   const [tableList, setTableList ] = useState();
   const [tableName, setTableName] = useState();
   const [lockStatus, setLockStatus] = useState();

   const nameRef = useRef();
  
      const joinMeeting = () =>{
        
        let tableId = meetId;
        const arrayTables = ["A3RVKRJ2J8Y1", "A3RVKRJ2J8Y2", 'A3RVKRJ2J8Y3', 'A3RVKRJ2J8Y4', 'A3RVKRJ2J8Y5',"A3RVKRJ2J8Y6"];
 
        tableId = tableId.slice(3,tableId.length - 3); 
        if(arrayTables.includes(tableId)){  
        if(tableId.length != 12){
          alert("Invalid table id");
      }else{
        let tableStatus = true;
        db.database()
        .ref()
        .on("value", (snap) => { 
            // const table = snap.val();  
            snap.forEach((child) => { 
              if(child.key === tableId ){
                tableStatus = false;
                const userList = [];
                for (const [key, row] of Object.entries(child.val())) {
                  for (const [index, value] of Object.entries(row)) { 
                    // alert("user Name:  "+value.userName);
                      if (value.userName != null && value.userName != undefined && value.userName != "") {
                        userList.push(value.userName);
                      }
                  }
                } 
                if(screenName === null || screenName === undefined || screenName === "" || screenName.length === 0){  
                  nameRef.current.focus();
                  setValidation(true);
                }
                else{
                  setValidation(false);
                  if(userList.length<6){ 
                    if(tableStatus=="create"){
                      const status = "create";
                      window.location.href = "/room" + "?id=" + tableId + "&name=" + screenName+"&status="+status; 
                    } else{
                      const status = "join";
                      window.location.href = "/room" + "?id=" + tableId + "&name=" + screenName+"&status="+status; 
                    }
                    // window.location.href = "/room" + "?id=" + tableId + "&name=" + screenName+ "&tableName="+tableName+"&lockStatus="+lockStatus;
                  }else{
                    alert("Table is full or Table is closed");
                    window.location.href = "/"; 
                  }
                }
              }
            
             });
             if(tableStatus){
              alert("Table doesn't exit");
            }
        });
      }
    }else{
      alert("Table doesn't exist");
    }
   }
 
     function handleScreenName(e) {
       setScreenName(e.target.value); 
       if(screenName === null || screenName === undefined || screenName === " " || screenName.length === 0){  
         setValidation(true);
       }else {
         setValidation(false); ;
       }
     }
      
 
     useEffect(() => { 
       
      //  alert("lockStatus  "+lockStatus)
        const urlparams = new URLSearchParams(window.location.search);
        const roomId = urlparams.get("id"); 
        const tableName =  urlparams.get("tableName");
        const lockStatus =  urlparams.get("lockStatus");
        setMeetId(roomId); 
        setLockStatus(lockStatus); 
        setTableName(tableName);
 
        // var ref = db.database().ref().child().getKey().equalTo(roomId);
        // console.log("database ",ref);
      
        
     }, []);
  
  return (
  <div>
      <Header />
    <div className="container Join-form">
        <div className="row ">
            <div className="col-md-4 offset-md-4">
            <div className='form-box '>
            <div className="form-group">
                    <label htmlFor="roomName">Enter Screen Name</label> 
                     <input type="text" ref={nameRef} autoComplete="off" required className= "form-control" onChange={(e) => handleScreenName(e)} id="screenName" placeholder="Enter Screen Name" />
                    {validation && <span className='errorMsg'>Screen name should not be empty </span>}
                    {validationLen && <p className='errorMsg'>Screen name should be more than 2 letters </p>}
                </div>
                <button className='btn btn-primary createBtn' onClick={()=>joinMeeting()}>
                        Join
                    </button>
                </div>
                </div>
                </div>
            </div>
        </div>
  )
}
