import React,{useRef, useEffect, useState} from 'react';  
import firepadRef, { db } from '../server/firebase';  
import { Modal, Button } from "react-bootstrap"; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Participant } from './Participants/Participant/Participant.component'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
toast.configure()

export default function AdminTables({echItm}) {

  const [showModal, setShowModal] = useState(false); 
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showUserModal, setShowUserModal] = useState(false); 
  const handleUserClose = () => setShowUserModal(false);
  const handleUserShow = (userKey) => {
      setUserId(userKey);
      setShowUserModal(true);}
     
   const [meetId, setMeetId] = useState(); 
   const [userId, setUserId] = useState();  
 
     const graph = useRef(null);

     const removeMeeting = () =>{
       db.database().ref().child(meetId).remove(); 
       setShowModal(false);
       toast.success("Table removed succesfully", {position: toast.POSITION.TOP_CENTER}); 
      }

      const removeUser = () =>{
         db.database().ref().child(meetId).child("participants").child(userId).remove();  
         setShowUserModal(false); 
         toast.success("User removed succesfully", {position: toast.POSITION.TOP_CENTER});       
       }
 
     useEffect(() => {   
       const ciclegraph = graph.current;
       const circleElements = ciclegraph.childNodes;
       setMeetId(echItm.id); 
       //setUserId(echItm.userKey);
        let angle = 360 - 90;
       let dangle = 360 / circleElements.length;
       
       for (let i = 0; i < circleElements.length; i++) {
         let circle = circleElements[i];
         angle += dangle;
         circle.style.transform = `rotate(${angle}deg) translate(${ciclegraph.clientWidth /2}px) rotate(-${angle}deg)`; 
       }
     }, []);

  return (
    
    <div>
      <span className='removeTable' onClick={handleShow} ><span className='tableText'>{echItm.tableName === undefined ? "Table" : echItm.tableName}</span><span className='trashIcon'> <FontAwesomeIcon icon={faTrash} /></span></span>
    <div className="ciclegraph showCursor" ref={graph} >  
    {echItm.users.map((user, seatId) =>  
    <div className={ user.status ? 'circle fillChair': 'circle emptyChair'}> 
         <img className={ user.status ? 'first-img1 chairAngle'+seatId: 'first-img1 chairAngle'+seatId}    src={ user.status? require('./../images/fillChair'+ seatId +'.png').default: require('./../images/empChair'+ seatId +'.png').default} alt="chair" />
         <div id={user.userName !== '' ? "arrow" :''}>
         <span className="badge titleNameJoin2" onClick={()=>handleUserShow(user.userKey)}>{user.userName}<span className='trashIcon'> <FontAwesomeIcon icon={user.status ? faTrash : ''} /></span></span> 
         </div>
    </div>
    
    )}
     {<Modal show={showUserModal} onHide={handleUserClose}>
        <Modal.Header className='text-center'>
          <Modal.Title>Are you sure you want to remove user from table?</Modal.Title>
        </Modal.Header> 
        <Modal.Footer className='rmTbFooter'>  
        <button className='btn btn-primary createBtn rmBtn' onClick={()=>removeUser ()}>Yes</button>
         <button className='btn btn-danger createBtn' onClick={handleUserClose}>No</button>
        </Modal.Footer>
      </Modal>} 

    </div>
    {<Modal show={showModal} onHide={handleClose}>
        <Modal.Header className='text-center'>
          <Modal.Title>Are you sure you want to remove table?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer className='rmTbFooter'>  
        <button className='btn btn-primary createBtn rmBtn' onClick={()=>removeMeeting ()}>Yes</button>
         <button className='btn btn-danger createBtn' onClick={handleClose}>No</button>
        </Modal.Footer>
      </Modal>}

      
  </div>
);
}
